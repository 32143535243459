<template>
	<div data-route>
		<page-header
			heading="Activity details"
		/>
		<div data-element="main">
			<activity
				entryRoute="admin"
				:lessonId="getLessonId"
				:activityId="getActivityId"
				:cancelRoute="getActivityRoute"
				:type="getType"
				:successRoute="getActivityRoute"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader   from '@/components/ui/PageHeader';
	import Activity     from '@/components/activities/edit/Activity';
	import actionClick  from '@/mixins/actionClick';
	import routeParams  from '@/mixins/routeParams';

	export default {
		metaInfo: {
			title: 'Activity details'
		},
		components: {
			PageHeader,
			Activity
		},
		mixins: [actionClick, routeParams],
		computed: {
			getType () {
				return this.$route.query.type;
			},
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getCourseRoute ||
						!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/${this.getLessonId}`;
			},
			getActivitiesRoute () {
				if (!this.getLessonRoute) {
					return false;
				}
				return `${this.getLessonRoute}/activities`;
			},
			getActivityRoute () {
				if (!this.getActivitiesRoute ||
						!this.getActivityId) {
					return false;
				}
				return `${this.getActivitiesRoute}/${this.getActivityId}`;
			}
		},
		created () {}
	};

</script>
